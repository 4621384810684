
export module ApiConstant {
    //ctg
    // const SERVER_URI = 'http://13.228.42.106:8090/api/';

    //kllayanpur
    //const SERVER_URI = 'http://3.0.109.66:8090/api/';

    //dhaka
     const SERVER_URI = 'https://www.drinkwellatm.com/Webapi/api/';
    
    // -- Staging Server
    // const SERVER_URI = 'http://13.214.1.75:81/api/';

    // Local
    // const SERVER_URI = 'http://localhost:63270/api/';
    // const SERVER_URI = 'http://13.215.60.137:81/api/';
    //const SERVER_URI = 'https://localhost:8086/api/';
    //const SERVER_URI = 'http://localhost:8081/api/';

    // Fetch API URL from the `env.js` file, fallback to a default if not found
    // export const SERVER_URI = window['env'] && window['env']['API_URL'] 
    //     ? window['env']['API_URL'] 
    //     : 'http://localhost:5000/';

    export const AccountApi = {
        AddAccount: SERVER_URI + 'account/add',
        UpdateAccount: SERVER_URI + 'account/update',
        RemoveAccount: SERVER_URI + 'account/remove',
        GetAccountById: SERVER_URI + 'account',
        GetAccountsByFilter: SERVER_URI + 'account/filter',
        GetBalance: SERVER_URI + 'account/get-balance'
    };

    export const LogApi = {

        GetDashboardByFilter: SERVER_URI + 'dashboardAuditLog/filter',
        GetDeviceByFilter: SERVER_URI + 'dashboardAuditLog/GetDeviceLogfilter',
        GetResponseLogByFilter: SERVER_URI + 'dashboardAuditLog/GetResponseLogByFilter',
        GetLoginLogByFilter: SERVER_URI + 'dashboardAuditLog/getLoginInfoFilter',

        GetWorkerLogByFilter: SERVER_URI + 'dashboardAuditLog/GetDeviceResponseLogByFilter',
        GetUserHistoryByFilter: SERVER_URI + 'dashboardAuditLog/GetUserHistoryfilter',
        GetRequestResponseLogByFilter: SERVER_URI + 'dashboardAuditLog/GetRequestResponseCount',
    };

    export const SalesForceReportApi = {
        SalesForceReportById: SERVER_URI + 'SalesForceReport',
        AddSalesForceReport: SERVER_URI + 'SalesForceReport/add',
        UpdateDailyReportt: SERVER_URI + 'SalesForceReport/update',
        GetDailyReportByFilter: SERVER_URI + 'SalesForceReport/filter',
    };

    export const CardApi = {
        AddCard: SERVER_URI + 'carddetail/add',
        UpdateCard: SERVER_URI + 'carddetail/update',
        RemoveCard: SERVER_URI + 'carddetail/remove',
        GetActiveCard: SERVER_URI + 'carddetail/all/active',
        GetCardDetailById: SERVER_URI + 'carddetail',
        GetAllCard: SERVER_URI + 'carddetail/all',
        CardAssingToAccount: SERVER_URI + 'carddetail/assignaccount',
        GetAllCardByAccount: SERVER_URI + 'carddetail/byAccount',

        GetCardListByFilter: SERVER_URI + 'carddetail/filter',

    };

    export const RechargeApi = {
        AddManualRecharge: SERVER_URI + 'rechargedetail/addmanualrecharge',
        filterRechargeDetail: SERVER_URI + 'rechargedetail/all'
    };
    export const BKashApi = {
        filterBKashTransaction: SERVER_URI + 'Payment/bkashtransaction/filter',
        filterBKashPayLoadLog: SERVER_URI + 'Payment/bkashpayLoadLog/filter'
    };

    export const UpayApi = {
        filterUpayTransaction: SERVER_URI + 'Payment/upaytransaction/filter',
        filterUpayPayLoadLog: SERVER_URI + 'Payment/upaypayLoadLog/filter'
    };

    export const ZoneApi = {
        AddZone: SERVER_URI + 'zone/add',
        UpdateZone: SERVER_URI + 'zone/update',
        SearchZone: SERVER_URI + 'zone/filter',
        GetAllZone: SERVER_URI + 'zone/dropdown',
        GetZone: SERVER_URI + 'zone/get-detail'
    };

    export const VendorApi = {
        AddVendor: SERVER_URI + 'Vendor/add',
        UpdateVendor: SERVER_URI + 'Vendor/update',
        SearchVendor: SERVER_URI + 'Vendor/filter',
        GetAllVendor: SERVER_URI + 'Vendor/dropdown',
        GetVendor: SERVER_URI + 'Vendor/get-detail'
    };

    export const BoothApi = {
        AddBooth: SERVER_URI + 'booth/add',
        UpdateBooth: SERVER_URI + 'booth/update',
        SearchBooth: SERVER_URI + 'booth/pumpfilter',
        // SearchBooth: SERVER_URI + 'booth/filter',
        GetAllBooth: SERVER_URI + 'booth/dropdown',
        GetBooth: SERVER_URI + 'booth/get-detail'
    };

    export const ReportApi = {
        initialization: SERVER_URI + 'report/initialization',
        waterDispenseAtmWise: SERVER_URI + 'report/atm-filter',
        waterDispenseCustomerWise: SERVER_URI + 'report/customer-atm',
        getPumpRechargeRevenue: SERVER_URI + 'report/pump-recharge-revenue',
        getRegisteredCustomer: SERVER_URI + 'report/registered-customer',
        getCustomerRecharge: SERVER_URI + 'report/customer-recharge',
        getCustomerWaterDispense: SERVER_URI + 'report/customer-water-dispense',
        getPumpWiseWaterTransaction: SERVER_URI + 'report/pump-wise-water-transaction'
    };

    export const DeviceApi = {
        AddDevice: SERVER_URI + 'device/add',
        UpdateDevice: SERVER_URI + 'device/update',
        SearchDevice: SERVER_URI + 'device/filter',
        GetDevice: SERVER_URI + 'device/get-details'
    };

    export const AuthenticationApi = {
        Login: SERVER_URI + 'security/login',
        Logout: SERVER_URI + 'logout',
        ChangePassword: SERVER_URI + 'security/change-password',
        ForgotPassword: SERVER_URI + 'forgotpassword',
        ResetPassword: SERVER_URI + 'security/reset-password'
    };

    export const UserApi = {
        AddUser: SERVER_URI + 'User/add',
        UpdateUser: SERVER_URI + 'User/update',
        FilterUser: SERVER_URI + 'user/userlist-by-filter',
        GetUserDetail: SERVER_URI + 'user/user-detail',
        GetUserType: SERVER_URI + 'user/usertype-dropdown',
        GetAllUser: SERVER_URI + 'user/GetAllUser',
        GetAppLoginRequestByUserName: SERVER_URI + 'user/app-login-request-by-userName',
        UpdateAppLoginRequest: SERVER_URI + 'user/update-app-login-request'
    };

    export const WaterTransactionApi = {
        filterWaterTransaction: SERVER_URI + 'water/transaction/filter',
        refundTransaction: SERVER_URI + 'water/transaction/refundTransaction'
    };

    export const WasaRechargeRequestApi = {
        WasaRechargeRequestById: SERVER_URI + 'WasaRechargeRequest',
        AddWasaRechargeRequest: SERVER_URI + 'WasaRechargeRequest/add',
        UpdateWasaRechargeRequest: SERVER_URI + 'WasaRechargeRequest/update',
        GetWasaRechargeRequestByFilter: SERVER_URI + 'WasaRechargeRequest/filter',
    }

    export const WaterCostApi = {
        UpdateWaterCost: SERVER_URI + 'water/costing/update',
        GetAllWaterCost: SERVER_URI + 'water/costing/get-all',
        GetWaterCost: SERVER_URI + 'water/costing/detail'
    };

    export const DashboardApi = {
        Status: SERVER_URI + 'dashboard/status',
        GetActiveATM: SERVER_URI + 'dashboard/GetActiveATM',
        GetActiveCustomer: SERVER_URI + 'dashboard/GetActiveCustomer',
        GetPumpList: SERVER_URI + 'dashboard/GetPumpList',
        GetLatestWaterDispenses: SERVER_URI + 'dashboard/GetLatestWaterDispenses',
        GetMonthlyWaterDispense: SERVER_URI + 'dashboard/GetMonthlyWaterDispense',
        GetNewAccountCountByMonth: SERVER_URI + 'dashboard/GetNewAccountCountByMonth',
        GetRechargeSumByMonth: SERVER_URI + 'dashboard/GetRechargeSumByMonth',
        GetTodayWaterDispense: SERVER_URI + 'dashboard/GetTodayWaterDispense',
        GetWaterDispenseSumByMonth: SERVER_URI + 'dashboard/GetWaterDispenseSumByMonth'
    };

    export const DashboardModernizationApi = {
        GetAllPump: SERVER_URI + 'deshboardModernization/getAllPump',
        GetTotalATM: SERVER_URI + 'deshboardModernization/getTotalATM',
        GetTotalCustomer: SERVER_URI + 'deshboardModernization/getTotalCustomer',
        GetTodayDispense: SERVER_URI + 'deshboardModernization/getTodayDispense',
        GetTodayRecharge: SERVER_URI + 'deshboardModernization/getTodayRecharge',
        GetAllMonthlyRegisteredCustomer: SERVER_URI + 'deshboardModernization/getAllMonthlyRegisteredCustomer',
        GetAllMonthlyWaterDispense: SERVER_URI + 'deshboardModernization/getAllMonthlyWaterDispense',
        GetAllMonthlyRecharge: SERVER_URI + 'deshboardModernization/getAllMonthlyRecharge',
        GetLastWaterWithdrawal: SERVER_URI + 'deshboardModernization/getLastWaterWithdrawal',
        GetDashBoardWeeklyAnalysDate: SERVER_URI + 'deshboardModernization/getDashBoardWeeklyAnalysDate',
        GetReportByPumName: SERVER_URI + 'deshboardModernization/getByPumName',
        GetByZone: SERVER_URI + 'deshboardModernization/getByZone',
        GetByLocationName: SERVER_URI + 'deshboardModernization/getByLocationName'
    };

    export const DashboardModernizationByPumpApi = {
        GetTodaysTotalRegistrationByPump: SERVER_URI + 'deshboardModernizationByPump/getTodaysTotalRegistrationByPump',
        GetTodaysTotalCustomerByPump: SERVER_URI + 'deshboardModernizationByPump/getTodaysTotalCustomerByPump',
        GetTodaysTotalDispenseByPump: SERVER_URI + 'deshboardModernizationByPump/getTodaysTotalDispenseByPump',
        GetTodaysTotalRechargeByPump: SERVER_URI + 'deshboardModernizationByPump/getTodaysTotalRechargeByPump',
        GetAllMonthlyRegisteredCustomerByPump: SERVER_URI + 'deshboardModernizationByPump/getAllMonthlyRegisteredCustomerByPump',
        GetAllMonthlyWaterDispenseByPump: SERVER_URI + 'deshboardModernizationByPump/getAllMonthlyWaterDispenseByPump',
        GetAllMonthlyRechargeByPump: SERVER_URI + 'deshboardModernizationByPump/getAllMonthlyRechargeByPump',
        GetLatestWaterWithdrawalByPump: SERVER_URI + 'deshboardModernizationByPump/getLatestWaterWithdrawalByPump',
        GetPumpByID: SERVER_URI + 'deshboardModernizationByPump/getPumpByID'
    };
}
